@charset "UTF-8";

/* color */
$color-text:#000000;
$color-blue:#43A3F9;
$color-black:#000000;
$color-base:#fff;
$color-gray:#EEEEEE;
$color-yellow:#F7FF00;
$color-orenge:#F26065;
$color-green:#00b848;

$theme_fonts: YuGothic,"ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;

/*font size*/
@mixin font-size($size, $base: 16) {
  font-size: $size + px;
  font-size: ($size / $base) * 1rem;
}

html{
  font-size:16px;
  @media screen and (max-width: 768px){
    font-size:13px;
  }
}

figure{
  margin: 0;
}

img{
  max-width: 100%;
}

body{
  @include font-size(16);
  font-family: $theme_fonts;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0.1em;
  color: $color-text;
  margin: 0;
  word-break: break-all;
  overflow-x: hidden;
}

p{
  margin: 0;
  margin-bottom: 1.2em;
}

.header{
  position: relative;
  z-index: 0;
  &__nav{
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 5;
    background-color: rgba($color-black,0.8);
  }
  ul{
    list-style: none;
  }
  a{
    display: block;
    color: $color-base;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    @include font-size(19);
    span{
      font-weight: normal;
      display: block;
      @include font-size(10);
    }
  }
  // &::before,&::after{
  //   content:"";
  //   position: absolute;
  //   top: 0;
  //   border: solid 270px transparent;
  //   @media screen and (max-width: 768px){
  //     border-width: 20vh;
  //   }
  //   @media screen and (max-width: 480px){
  //     border-width: 10vh;
  //   }
  // }
  // &::before{
  //   left: 0;
  //   z-index: 4;
  //   border-left: solid 75vw rgba($color-blue,0.8);
  //   border-top-width: 0;
  // }
  // &::after{
  //   right: 0;
  //   z-index: 3;
  //   border-right: solid 75vw rgba($color-black,0.8);
  //   border-top-width: 0;
  // }
}

.visual{
  position: relative;
  // max-height: 100vh;
  padding-bottom: 56.25%;
  overflow: hidden;
  @media screen and (max-width: 576px){ padding-bottom: 50vh; }
  &::before{
    content:"";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(#F9F9F9,0.5);
    z-index: 2;
  }
  iframe{
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}



/* ここより上がFV */

@keyframes popup {
  0% {top: -5em;}
  100% {top: -3em;}
}

/////////// title
.title{
  @include font-size(48);
  // font-size: 3.3vw;
  line-height: 1;
  margin: 0;
  margin-bottom: 0.3em;
  color: $color-blue;
  @media screen and (max-width: 768px){ font-size:6vw; }
  @media screen and (max-width: 576px){ font-size:8vw; }
  &__wrap{
    margin-bottom: 4em;
    position: relative;
    &_content{
      border-left: solid 5px $color-blue;
      padding-left: 1em;
      margin-bottom: 4em;
    }
  }
  &__before{
    font-weight: bold;
    position: absolute;
    top: -5em;
    left: 50%;
    display: inline-block;
    transform: translateX(-50%);
    margin-bottom: 0px;
    line-height: 1.3;
    @include font-size(20);
    // @media screen and (max-width: 768px){ font-size:4.2vw; }
    @media screen and (max-width: 576px){ font-size:4.2vw; }
    animation-name: popup;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;

    span{
      @include font-size(16);
      // @media screen and (max-width: 768px){ font-size:4.2vw; }
      @media screen and (max-width: 576px){ font-size:3vw; }
    }
    &:before,&:after{
      width: 1px;
      height: 2em;
      background-color: $color-black;
      content: "";
      display: inline-block;
      position: absolute;
      bottom: 0%;
    }
    &:before{
      left: -2em;
      transform: rotate(-20deg);
    }
    &:after{
      right: -2em;
      transform: rotate(20deg);
    }
  }
  &__sub{
    @include font-size(12);
    margin-bottom: .5em;
    &_large{
      color: $color-blue;
      @include font-size(24);
      font-weight: bold;
    }
    // font-size:0.8vw;
    @media screen and (max-width: 768px){ font-size:2vw; }
    @media screen and (max-width: 576px){ font-size:2.5vw; }
  }
  &__content{
    @include font-size(32);
    // font-size:2.4vw;
    @media screen and (max-width: 768px){ font-size:4vw; }
    @media screen and (max-width: 576px){ font-size:5.5vw; }
  }
  &__option{
    @include font-size(18);
    color: #999999;
    // font-size:1.2vw;
    @media screen and (max-width: 768px){ font-size:2.2vw; }
    @media screen and (max-width: 576px){ font-size:3vw; }
  }
}



////////// text
.text{
  &-align{
    &-center{
      text-align: center;
    }
    &-left{
      text-align: left;
    }
    &-right{
      text-align: right;
    }
  }
  &-size{
    &-large{
      font-size: 110%;
    }
    &-small{
      font-size: 90%;
    }
  }
}


///////// line
.line{
  background: linear-gradient(transparent 50%, $color-yellow 50%);;
  position: relative;
  &::before{
  }
}


///////// background
.background{
  &__gray{
    background-color: $color-gray;
  }
  &__lightgray{
    background-color: lighten($color-gray, 3%);
  }
  &__black{
    background-color: #272727;
    color: $color-base;
  }
}


///////// content
.content{
  background-color: $color-base;
  padding: 4em 0.5em 0em;
  margin-top: -90px;
  @media screen and (max-width: 768px){ margin-top: -5vh; }
  &__letter{
    position: relative;
    &::after{
      content:"";
      position: absolute;
      right: 0;
      top: 0;
      border: solid 30px $color-blue;
      border-top: solid 30px $color-gray ;
      border-right: solid 30px $color-gray ;
      @media screen and (max-width: 576px){
        border: solid 15px $color-blue;
        border-top: solid 15px $color-gray ;
        border-right: solid 15px $color-gray ;
      }
    }
  }
}

//////// btn
.btn{
  background-color: $color-blue;
  padding: 1em 3em;
  display: inline-block;
  text-decoration: none;
  color: $color-base;
  z-index: 2;
  @include font-size(24);
  // font-size:1.6vw;
  font-weight: bold;
  position: relative;
  z-index: 2;
  left: 0px;
  top: 0px;
  @media screen and (max-width: 768px){ font-size:3vw; }
  @media screen and (max-width: 576px){ font-size:3.6vw; }
  &__wrap{
    position: relative;
    display: inline-block;
    &::before{
      content:"";
      display: block;
      position: absolute;
      right: -5px;
      bottom: -5px;
      background-color: #6E6E6E;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  &:hover{
    left: 5px;
    top: 5px;
    transition: all .2s ease;
  }
  &__small{
    @include font-size(18);
    padding-top: .5em;
    padding-bottom: .5em;
  }
  &__danger{
    background-color: $color-orenge;
  }
  &__black{
    background-color: $color-black;
  }
  &__line{
    background-color: $color-green;
  }
  &__cvp{
    @include font-size(42);
    font-weight: bold;
    padding-left: .5em !important;
    padding-right: .5em !important;
    max-width: 900px;
    width: 100%;
    span{
      @include font-size(16);
      // font-weight: normal;
      display: block;
    }
  }
}


//////// skew
.skew{
  &::before,&::after{
    content:"";
    border-style: solid;
    border-width: 0;
    border-color: $color-base;
  }
  &::before{
    border-top-width: 10vh;
    border-right-width: 100vw;
    border-right-color: $color-gray;
  }
  &::after{
    border-bottom-width: 10vh;
    border-left-width: 100vw;
    border-left-color: $color-gray;
  }
  &__trapezoid{
    &::before,&::after{
      content:"";
      border-style: solid;
      border-width: 0;
      border-color: $color-base;
    }
    &::before{
      border-top-width: 10vh;
      border-left-width: 100vw;
      border-left-color: #272727;
    }
    &::after{
      border-bottom-width: 10vh;
      border-left-width: 100vw;
      border-left-color: #272727;
    }
  }
  &.about{
    &::before,&::after{
      content:"";
      border-style: solid;
      border-width: 0;
      border-color: $color-base;
    }
    &::before{
      border-top-width: 10vh;
      border-left-width: 100vw;
      border-left-color: lighten($color-gray, 3%);
    }
    &::after{
      border-bottom-width: 10vh;
      border-right-width: 100vw;
      border-right-color: lighten($color-gray, 3%);
    }
  }
}


//////// img
.img{
  &__fit{
    height: 100%;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
  &__bg{
    &_gray{
      background-color: $color-gray;
      img{
        background-color: $color-base;
      }
    }
  }
  &__cercle{
    display: flex;
    align-items: center;
    justify-content: center; 
    background-color: $color-base;
    border-radius: 50%;
    padding: 3px;
    background: linear-gradient($color-blue 0%,$color-blue 49%,#6E6E6E 50%);
    position: relative;
    overflow: hidden;
    transform: rotate(-25deg);
    transition: .3s all ease-in;
    &:hover{
      transform: rotate(-270deg);
      .img__cercle_inner{
        transform: rotate(270deg);
        img{
          transform: scale(1.2);
        }
      }
    }
    &_inner{
      transform: rotate(25deg);
      border-radius: 50%;
      padding: 2.4em 2.7em;
      background-color: #fff;
      img{
        transform: scale(1);
      }
    }
  }
  &__radius{
    border-radius:50%;
    display: inline-block;
    padding: 1em 1.3em;
    background-color: $color-base;
  }
}


//////// border
.border{
  border: solid 1px #C5C5C5;
}


//////// card
.card{
  padding-left: 0.8em;
  padding-right: 0.8em;
  .img__radius{
    margin-top: -60px;
  }
  &__title{
    @include font-size(24);
    // font-size:1.6vw;
    color: $color-blue;
    margin: 0.2em 0;
    @media screen and (max-width: 768px){ font-size:3.5vw; }
    @media screen and (max-width: 576px){ font-size:4.2vw; }
    span{
      color: $color-orenge;
    }
  }
  &__text{
    text-align: left;
  }
}

//////// bill
.bill{
  background:$color-base;
  &__title{
    color: $color-base;
    background:$color-blue;
    padding: 0.2em 0.5em;
    text-align: center;
    @include font-size(24);
    // font-size:1.6vw;
    margin: 0;
    @media screen and (max-width: 768px){ font-size:3.5vw; }
    @media screen and (max-width: 576px){ font-size:4vw; }
  }
  &__price{
    padding-top: 0.4em;
    line-height: 1;
    color: $color-orenge;
    text-align: center;
    @include font-size(42);
    big{
      @include font-size(60);
    }
    small{
      @include font-size(24);
    }
  }
  &__text{
    color: $color-text;
    padding: 1.2em 1.4em;
    p{
      margin-bottom: 0;
    }
  }
}


//////// service
.service{
  p{
    @include font-size(16);
    // font-size: 1.1vw;
    padding: 0.4em 0 0 0;
    @media screen and (max-width: 768px){ font-size:2.3vw; }
    // @media screen and (max-width: 576px){ font-size:2.3vw; }
  }
}

//////// price
.price{
  p{
    margin-bottom: 0;
  }
  &__subtitle{
    @include font-size(24);
    // font-size:1.6vw;
    margin-bottom: 0;
    @media screen and (max-width: 768px){ font-size:3.5vw; }
    @media screen and (max-width: 576px){ font-size:4.2vw; }
  }
  &__text{
    margin-bottom: 1em;
    p{
      @include font-size(12);
      // font-size: 1vw;
      margin: 0;
      @media screen and (max-width: 768px){ font-size:2vw; }
      // @media screen and (max-width: 576px){ font-size:2.2vw; }
    }
  }
  .keyword{
    border: solid 1px $color-base;
    padding: 0.3em;
    @include font-size(14);
    // font-size:1vw;
    text-align: center;
    @media screen and (max-width: 768px){ font-size:2.5vw; }
    @media screen and (max-width: 576px){ font-size:3.5vw; }
  }
}


//////// merit
.merit{
  counter-increment: merit;
  &__title{
    @include font-size(24);
    background-color: $color-blue;
    color: $color-base;
    padding: .5em 1em .5em 2.5em;
    position: relative;
    margin-bottom: .7em;
    @media screen and (max-width: 768px){ font-size:3vw; }
    @media screen and (max-width: 576px){ font-size:4.2vw; }
    &:before{
      content: '';
      display: block;
      width: 0px;
      height: 0px;
      border-top: 1.5em solid $color-base;
      border-right: 1.5em solid transparent;
      border-bottom: 1.5em solid transparent;
      border-left: 1.5em solid $color-base;
      position: absolute;
      left: 0px;
      top: 0px;
    }
    &:after{
      content: counter(merit);
      position: absolute;
      left: .5em;
      top: 0px;
      font-family: serif;
      font-style: italic;
      color: $color-blue;
    }
  }
  &__content{

  }
}


//////// about
.about{
  border-bottom: 1px solid $color-gray;
  &:last-child{
    border-bottom: 0px;
    margin-bottom: 0px;

  }
  &__title{
    margin-top: 0px;
    @include font-size(24);
    padding-left: .5em;
    border-left: 5px solid $color-blue;
    @media screen and (max-width: 768px){ font-size:3vw; }
    @media screen and (max-width: 576px){ font-size:4.2vw; }
  }
  &__content{

  }
}


//////// contact

@mixin placeholder($color) {
  &::placeholder {
    color: $color;
  }
  // IE
  &:-ms-input-placeholder {
    color: $color;
  }
  // Edge
  &::-ms-input-placeholder {
    color: $color;
  }
}

.contact{
  max-width: 768px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.contact-item{
  align-items: center;
  &__group{
    border-bottom: 2px solid $color-gray;
    padding-bottom: .5em;
    margin-bottom: 1em;
  }
  &__content,&__label{
    padding: .5em 1em;
    margin-bottom: .5em;
  }
  &__content{

  }
  &__important{
    font-size: 12px;
    color: $color-orenge;
  }
  &__label{
    font-weight: bold;
    display: block;
  }
  &__radio{
    &_inline{
      display: inline;
    }
  }
  &__input,&__textarea{
    transition: all .3s ease;
    width: 100%;
    box-sizing: border-box;
    padding: 1em;
    border: 1px solid $color-gray;
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0px 0px 5px darken($color-gray, 10%);
    font-size: 16px;
    font-family: $theme_fonts;
    @include placeholder(darken($color-gray,25%));
    &:focus{
      box-shadow: 0px 0px 5px darken($color-blue, 30%);
    }
    &.important{
      box-shadow: 0px 0px 5px darken($color-orenge, 10%);
      &_false{
        box-shadow: 0px 0px 5px darken($color-gray, 10%);
      }
      &:focus{
        box-shadow: 0px 0px 5px darken($color-blue, 30%);
      }
    }
  }
  &__input{
  }
  &__textarea{
  }
  &__submit{
    border: 0px;
    padding: .5em 3em;
    background-color: $color-orenge;
    &::focus{
      color: $color-blue;
    }
  }
  &__privacy{
    padding: .3em .5em;
    background-color: $color-gray;
    border: 1px solid darken($color-gray, 10%);
    display: inline-block;
    margin-top: 1em;
    margin-bottom: 1em;
    border-radius: 3px 3px 3px 3px;
  }
}



.wrap__main{
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}

/*******************************
  Modal
*******************************/

.my-modal{
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: rgba($color-blue, .95);
  width: 100%;
  height: 100%;
  z-index: -10;
  transform: scale(.5);
  opacity: 0;
  filter: blur(100px);
  border-radius: 100%;
  transition: ease .5s all;
  color: white;
  &__on{
    z-index: 30000;
    opacity: 1;
    transform: scale(1);
    filter: blur(0px);
    border-radius: 0;
  }
  &__inner{
    height: 90vh;
    margin-top: 5vh;
    &_device_pc{
      overflow-y: auto;
    }
  }
  &__content{
    overflow-y: auto;
  }
  &__close{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 60px;
    height: 60px;
    padding-top: 30px;
    transition: .3s ease all;
    cursor: pointer;
    z-index: 30000;
    &:before,&:after{
      content: '';
      display: block;
      width: 60px;
      height: 1px;
      background-color: $color-base;
      transition: .3s ease all;
    }
    &:before{ transform: rotate(-45deg); }
    &:after{ transform: rotate(45deg); }
    &:hover{
      &:before,&:after{
        width: 30px;
        margin-left: auto;
        margin-right: auto;
      }
      &:before{ transform: rotate(0deg); }
      &:after{ transform: rotate(0deg); }
    }
  }
}


.modal{
  cursor: pointer;
  span{
    text-decoration: underline;
  }
  &__title{
    background-color: #F2F2F2;
    font-size: 24px;
    padding: 0.3em 1em;
  }
}

.modal-content{
  display: none;
  &__figure{
    margin-bottom: 2em;
  }
}



/*******************************
  footer
*******************************/

.footer{
  color: $color-base;
  background-color: $color-black;
  text-align: center;
  @include font-size(12);
  padding: 1em;
  position: relative;
  z-index: 9999;
}


/*******************************
  fixed-contents
*******************************/

.fixed-contents{
  border-bottom: 5px solid $color-blue;
  position: fixed;
  bottom: -500px;
  left: 0px;
  width: 100%;
  transition: 1s ease-in all;
  z-index: 9998;
  // background-color: $color-base;
  &__state{
    &_ready{
      bottom: -300px;
    }
    &_fixed{
      bottom: 0px;
    }
  }
  &__btn{
    @media screen and (max-width: 768px){
      padding: 0px 1px;
    }
    .btn{
      width: 100%;
      text-align: center;
      @media screen and (max-width: 768px){
        padding-left: 1em;
        padding-right: 1em;
      }
      @media screen and (max-width: 768px){ font-size:2.5vw; }
      @media screen and (max-width: 576px){ font-size:3.3vw; }
      &:hover{
        left: 0px;
      }
    }
  }
}


//////// sp-nav

.sp-nav{
  list-style-type: none;
  padding-left: 2em;
  padding-right: 2em;
  li{
    padding: 1em;
    color: $color-blue;
    @include font-size(18);
    text-align: center;
    border-bottom: 1px solid $color-base;
    &:last-child{
      border-bottom: 0px;
    }
    a{
      text-decoration: none;
      font-weight: bold;
      color: $color-base;
      @include font-size(12);
      span{
        display: block;
        @include font-size(20);
      }
    }
    span{
    }
  }
}

.check{
  &__box{
    border:solid 1px #ccc;
    padding: 1em 2em;
  }
}

/*******************************
  table
*******************************/
.table{
  &__td{
    &_first,&_last{
      padding: 0.5em 1em;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      vertical-align: middle;
    }
    &_first{
      width: 30%;
      text-align: center;
      font-family: $theme_fonts;
      font-weight: bold;
      border-bottom-color: $color-yellow;
    }
    &_last{
      width: 70%;
      text-align: left;
      border-bottom-color: white;
    }
  }
}

/*******************************
  pickup
*******************************/

.pickup{
  &__title{
    padding-top: .5em;
    @include font-size(30);
  }
  &__list{
    list-style-type: none;
    padding-left: 0px;
    li{
      @include font-size(18);
      font-weight: bold;
      padding: .3em .5em;
      border-bottom: 1px solid $color-gray;
      &:last-child{
        border-bottom: 0px;
      }
      b{
        font-size: 110%;
        color: $color-orenge;
      }
      i{
        color: $color-green;
        margin-right: .5em;
      }
    }
  }
}
